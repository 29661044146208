import Close from "@mui/icons-material/Close";
import { Alert, IconButton, Switch } from "@mui/material";
import axios from "axios";
import { noop } from "lodash";
import { useState } from "react";

const OptOutBanner = () => {
  const [showOptOutBanner, setShowOptOutBanner] = useState<boolean>(
    localStorage.getItem("showOptOutBanner") !== "false"
  );
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.checked) {
        await axios.patch("/api/v1/companies/opt_out_from_feature", {
          feature: "chats",
        });
        window.location.href = "/publisher/campaigns";
      }
    } catch (error) {
      noop();
    }
  };

  const handleClose = () => {
    localStorage.setItem("showOptOutBanner", "false");
    setShowOptOutBanner(false);
  };

  if (!showOptOutBanner) {
    return null;
  }

  return (
    <Alert
      severity="info"
      icon={
        <IconButton onClick={handleClose} size="small">
          <Close />
        </IconButton>
      }
      sx={{ mb: 2, alignItems: "center" }}
      action={
        <Switch onChange={handleChange} defaultChecked={showOptOutBanner} />
      }
    >
      <b>New Chat View!</b> We switched you to our new chat-style sponsorship
      view! You can toggle back to the old view anytime for the next 3 months.
    </Alert>
  );
};

export default OptOutBanner;

import { fromUnixTime, differenceInCalendarDays } from "date-fns";

// At the top of the file
const WEEK_THRESHOLD = 7;
const URGENT_THRESHOLD = 3;

export function getDeadlineColor(deadlineTimestamp: number | null): string {
  if (!deadlineTimestamp) {
    return "error.main";
  }
  if (deadlineTimestamp < 0 || isNaN(deadlineTimestamp)) {
    return "error.main";
  }
  const deadlineDate: Date = fromUnixTime(deadlineTimestamp);

  const currentDate: Date = new Date();

  const daysBetween: number = differenceInCalendarDays(
    deadlineDate,
    currentDate
  );

  if (daysBetween > 7) {
    return "text.disabled";
  } else if (daysBetween >= 4 && daysBetween <= WEEK_THRESHOLD) {
    return "warning.main";
  } else if (daysBetween >= 0 && daysBetween <= URGENT_THRESHOLD) {
    return "error.main";
  } else {
    return "error.main";
  }
}

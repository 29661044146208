import { useCallback } from "react";
import { UserType } from "types";
import { sendMessage as apiSendMessage, getMessages } from "../api/chatsAPI";
import { ChatAction, ChatActions } from "../types/chatActions";

export const useMessages = (
  dispatch: React.Dispatch<ChatAction>,
  campaignId: string | undefined,
  trackEvent: (event: string, properties?: Record<string, unknown>) => void,
  userType: UserType | undefined,
  companyName: string,
  messagesContainerRef: React.RefObject<HTMLDivElement>
) => {
  const fetchMessages = useCallback(
    async (showLoading = false, isPolling = false, callback?: () => void) => {
      if (!campaignId) return;
      if (showLoading || isPolling) {
        dispatch({
          type: ChatActions.FETCH_MESSAGES_REQUEST,
          payload: { isPolling },
        });
      }
      try {
        const data = await getMessages(campaignId);
        dispatch({
          type: ChatActions.FETCH_MESSAGES_SUCCESS,
          payload: { messages: data, isPolling },
        });
        if (callback) {
          setTimeout(callback, 1000);
        }
      } catch {
        dispatch({
          type: ChatActions.FETCH_MESSAGES_FAILURE,
          payload: { isPolling },
        });
      }
    },
    [campaignId, dispatch]
  );

  const sendMessage = useCallback(
    async (messageText: string, attachment?: File) => {
      if (!campaignId) return;

      const tempMessage = {
        uuid: `temp-${Date.now()}`,
        message: messageText,
        attachment: attachment
          ? { url: URL.createObjectURL(attachment), filename: attachment.name }
          : undefined,
        created_at: Math.floor(Date.now() / 1000),
        author: { name: companyName, logo: "" },
      };
      dispatch({ type: ChatActions.SEND_MESSAGE, payload: tempMessage });

      trackEvent(`${userType}_sent_message`, {
        campaignId: campaignId,
        attachment: attachment
          ? {
              url: URL.createObjectURL(attachment),
              filename: attachment.name,
              type: attachment.type,
              size: attachment.size,
            }
          : undefined,
        messageLength: messageText.length,
      });

      try {
        await apiSendMessage(campaignId, messageText, attachment);
        messagesContainerRef.current?.scrollIntoView();
      } catch {
        // Optionally handle error and revert optimistic update
      }
    },
    [
      campaignId,
      companyName,
      dispatch,
      trackEvent,
      userType,
      messagesContainerRef,
    ]
  );

  return { fetchMessages, sendMessage };
};

import { useCallback, useContext } from "react";
import { UserContext } from "context";
import { UserType } from "types";

interface SegmentDataLayer {
  state: string;
  email: string;
  first_name: string;
  last_name: string;
  firstName: string;
  lastName: string;
  name: string;
  id: number;
  type: UserType;
  company_name: string;
  company: string;
  created: string;
  created_ts: number;
}
type SpecWindow = typeof window & {
  analytics?: {
    identify: (userId: number, traits: SegmentDataLayer) => void;
    track: (event: string, properties?: { [key: string]: any }) => void;
  };
};
export const useTrackEvent = () => {
  const { userData } = useContext(UserContext);
  const { userType, companyName } = userData;

  const trackEvent = useCallback(
    (eventName: string, properties: { [key: string]: any } = {}) => {
      if (!userType) {
        return;
      }

      if ((window as SpecWindow).analytics) {
        const segmentDataLayer: SegmentDataLayer = {
          state: "logged in",
          email: userData.email || "",
          firstName: userData.firstName,
          lastName: userData.lastName,
          name: `${userData.firstName} ${userData.lastName}`,
          id: userData.ro_id as number,
          type: userType as UserType,
          company_name: companyName,
          company: companyName,
          created: userData.createdAt.toISOString(),
          created_ts: userData.createdAt.getTime(),
          first_name: userData.firstName,
          last_name: userData.lastName,
        };

        (window as SpecWindow).analytics?.identify(
          userData.ro_id as number,
          segmentDataLayer
        );

        (window as SpecWindow).analytics?.track(eventName, properties);
      }
    },
    [userData, userType, companyName]
  );

  return { trackEvent, userType, companyName };
};

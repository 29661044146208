import React, { useMemo } from "react";
import { InstantSearch, useRelatedProducts } from "react-instantsearch";
import ConnectedAutoComplete from "./ConnectedAutoComplete";
import { SearchClient } from "algoliasearch/lite";

type AlogoliaSearchProps = {
  indexName: string;
  searchClient: SearchClient;
  isEmbedded: boolean;
  objectId?: string;
};

const RelatedItemsAutoComplete = ({
  objectId,
  isEmbedded,
}: {
  objectId: string;
  isEmbedded: boolean;
}) => {
  const { items } = useRelatedProducts({
    objectIDs: [objectId],
  });

  const relatedProducts = useMemo(() => {
    return items.slice(0, 5);
  }, [items]);

  return (
    <ConnectedAutoComplete
      relatedProducts={relatedProducts}
      isEmbedded={isEmbedded}
    />
  );
};

export default function AlogoliaSearch({
  indexName,
  searchClient,
  isEmbedded,
  objectId,
}: AlogoliaSearchProps) {
  if (objectId)
    return (
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <RelatedItemsAutoComplete objectId={objectId} isEmbedded={isEmbedded} />
      </InstantSearch>
    );

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <ConnectedAutoComplete isEmbedded={isEmbedded} />
    </InstantSearch>
  );
}

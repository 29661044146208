import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ChatHeader from "./ChatHeader";
import MessageInput from "./MessagesInput";
import { MessageList } from "./MessagesList";
import SponsorshipDetails from "./SponsorshipDetails";

const ChatWindow: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const isDetailsPage = location.pathname.endsWith("details");

  const chatContent = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
      height={"100%"}
      position="relative"
      sx={{
        borderRight: { sm: `1px solid ${theme.palette.grey[300]}` },
      }}
    >
      <MessageList />
      <MessageInput />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      sx={{
        backgroundColor: "#FFFFFF",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "12px",
        overflow: "hidden",
      }}
    >
      <ChatHeader />

      <Box display="flex" flexGrow={1} sx={{ overflow: "hidden" }}>
        {!isMobile ? (
          <>
            {chatContent}
            <SponsorshipDetails />
          </>
        ) : (
          <>{!isDetailsPage ? chatContent : <Outlet />}</>
        )}
      </Box>
    </Box>
  );
};

export default ChatWindow;

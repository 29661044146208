import { Button } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useClearRefinements, useInstantSearch } from "react-instantsearch";
import { MIN, MAX } from "./SponsorshipCostSlider";
import { INDEX_NAME } from "./SortBy";

interface UIState {
  range?: {
    list_size?: string;
    [key: string]: string | undefined;
  };
  refinementList?: {
    [key: string]: string[] | undefined;
  };
  toggle?: {
    [key: string]: boolean | undefined;
  };
}

export const hasActiveUserFilters = (
  uiState: UIState,
  priceValue: number[],
  defaultPrice: number[]
) => {
  const defaultListSize = "0:";
  const { range = {}, refinementList = {}, toggle = {} } = uiState;

  const isListSizeModified = range.list_size !== defaultListSize;
  const isOtherRangePresent = Object.keys(range).some(
    (key) => key !== "list_size"
  );
  const isRefinementListApplied = Object.keys(refinementList).some(
    (key) => (refinementList[key]?.length ?? 0) > 0
  );
  const isToggleApplied = Object.keys(toggle).some((key) => toggle[key]);

  const isPriceModified =
    priceValue[0] !== defaultPrice[0] || priceValue[1] !== defaultPrice[1];

  return (
    isListSizeModified ||
    isOtherRangePresent ||
    isRefinementListApplied ||
    isToggleApplied ||
    isPriceModified
  );
};

function CustomClearRefinements({
  priceValue,
  setPriceValue,
}: {
  priceValue: number[];
  setPriceValue: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const { refine } = useClearRefinements();
  const { setUiState, uiState } = useInstantSearch();

  const state = uiState[INDEX_NAME];

  const [clearFilters, setClearFilters] = useState(false);

  const canReset = hasActiveUserFilters(state, priceValue, [MIN, MAX]);

  const handleClear = useCallback(() => {
    setPriceValue([MIN, MAX]);
    setClearFilters(true);
  }, [setPriceValue]);

  useEffect(() => {
    if (clearFilters) {
      setUiState((prevState) => prevState); // No-op update to retrigger search with updated priceValue
      setClearFilters(false);
    }
  }, [clearFilters, setClearFilters, setUiState, priceValue]);

  const handleButtonClick = () => {
    handleClear();
    refine();
  };

  return (
    <Button
      variant="text"
      onClick={handleButtonClick}
      disabled={!canReset}
      disableRipple
      sx={{
        color: "primary.main",
        textDecoration: "underline",
        padding: "10px 0px",
        boxShadow: "none",
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        "&:active": {
          color: "primary.dark",
          textDecoration: "underline",
        },
      }}
    >
      Clear Filters
    </Button>
  );
}

export default CustomClearRefinements;

/**
 * Hook to determine the color of the campaign status badge.
 */

const useCampaignStatusColor = (
  status: string
): "error" | "primary" | "warning" | "success" | "default" => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "Canceled":
        return "error";
      case "Requested - Date Change":
      case "Requested":
      case "Creative":
      case "Payment":
      case "Awaiting Draft":
      case "Approval":
        return "primary";
      case "Scheduled":
        return "warning";
      case "Completed":
      case "Sent":
        return "success";
      default:
        return "default";
    }
  };

  return getStatusColor(status);
};

export default useCampaignStatusColor;

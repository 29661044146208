import React, { useState, useRef } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  LinearProgress,
  Link,
  Stack,
  Toolbar,
} from "@mui/material";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import { User, InboxStatus } from "../types";

import type { SearchClient } from "algoliasearch/lite";
import AlogoliaSearch from "../AlogoliaSearch";

import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from "@knocklabs/react";

import "@knocklabs/react/dist/index.css";
import { theme } from "../index";
import AdvertiserSubMenu from "./AdvertiserSubMenu";

const apiKey = process.env.REACT_APP_KNOCK_API_KEY;
const feedId = process.env.REACT_APP_KNOCK_FEED_ID;

const knockAppEnable = !!localStorage.getItem("knockAppEnable");

function isPublicPage() {
  return [
    /^\/marketplace/,
    /^\/categories\/.*/,
    /^\/collections\/.*/,
    /^\/search?.*/,
  ].some((pattern) => pattern.test(window.location.pathname));
}

type HeaderProps = {
  userData: User | null;
  isDataLoading: boolean;
  isEmbedded: boolean;
  searchClient: SearchClient | null;
  indexName: string;
  inboxStatus?: InboxStatus | null;
  objectId?: string;
};

export function Header({
  inboxStatus,
  userData,
  isDataLoading,
  isEmbedded,
  searchClient,
  indexName,
  objectId,
}: HeaderProps) {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);

  const isProfilePage = /^\/sites\/.*/.test(window.location.pathname);

  const userId = userData?.ro_id;

  return (
    <AppBar
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
      }}
      position="sticky"
    >
      <Container>
        <Toolbar
          disableGutters
          sx={{ position: "relative", backgroundColor: "white" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexGrow={1}
            alignItems="center"
          >
            <Logo
              usertype={userData?.userType}
              hasMarketPlaceAccess={userData?.has_marketplace_access}
            />
            {!!searchClient && !isPublicPage() && (
              <Box flexGrow={1} ml="24px" mr="12px">
                <AlogoliaSearch
                  searchClient={searchClient}
                  indexName={indexName}
                  isEmbedded={isEmbedded}
                  objectId={objectId}
                />
              </Box>
            )}
            {!!apiKey && !!userId && feedId && knockAppEnable && (
              <KnockProvider apiKey={apiKey} userId={String(userId)}>
                <KnockFeedProvider feedId={feedId}>
                  <Box sx={{ color: "#0e1952", ml: "auto" }}>
                    <NotificationIconButton
                      ref={notifButtonRef}
                      onClick={() => setIsVisible(!isVisible)}
                    />
                    <NotificationFeedPopover
                      buttonRef={notifButtonRef}
                      isVisible={isVisible}
                      onClose={() => setIsVisible(false)}
                    />
                  </Box>
                </KnockFeedProvider>
              </KnockProvider>
            )}
            {userData?.userType === "publisher" && (
              <Button
                variant="outlined"
                size="small"
                href="/publisher/referrals"
                sx={{
                  borderColor: theme.palette.primary.main,
                  ml: "auto",
                  mr: 2,
                }}
              >
                Referral Program
              </Button>
            )}
            {!!userData ? (
              <UserMenu
                userData={userData}
                isEmbedded={isEmbedded}
                inboxStatus={inboxStatus}
              />
            ) : (
              <Stack direction="row" spacing={1}>
                <Link href="/users/sign_in" color="inherit">
                  <Button variant="text" color="primary">
                    Log In
                  </Button>
                </Link>
                <Link href="/users/sign_up" color="inherit">
                  <Button variant="contained" color="primary">
                    Sign Up
                  </Button>
                </Link>
              </Stack>
            )}
          </Box>
        </Toolbar>
      </Container>
      {isProfilePage && <AdvertiserSubMenu />}
      {isDataLoading && <LinearProgress />}
    </AppBar>
  );
}

import { useMediaQuery, useTheme } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useChat } from "../context/ChatContext";
import { isSupportedFileType, MAX_FILE_SIZE } from "../utils/fileUtils";

const MAX_TEXT_LENGTH = 1500;

/**
 * Hook to manage message input, including handling attachments and sending messages.
 */
const useMessageInput = () => {
  const { campaignId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [messageText, setMessageText] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false); // Track sending state
  const { fetchMessages, sendMessage } = useChat();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (textFieldRef.current && !isMobile) {
      textFieldRef.current.focus();
    }
  }, [campaignId, isMobile]); // Focuses input when campaignId changes

  useEffect(() => {
    if (attachment?.type?.startsWith("image/")) {
      const url = URL.createObjectURL(attachment);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setPreviewUrl(null);
    }
  }, [attachment]);

  useEffect(() => {
    setMessageText(""); // Clear the message input when campaign changes
  }, [campaignId]);

  const handleAttachmentChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!isSupportedFileType(file.type)) {
      setError("Unsupported file type. Please select a supported file.");
      handleRemoveAttachment();
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setError("File size exceeds 20 MB. Please select a smaller file.");
      handleRemoveAttachment();
      return;
    }
    setAttachment(file);
  };

  const handleRemoveAttachment = () => {
    setAttachment(null);
    setPreviewUrl(null);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleSend = async () => {
    if (isSending) return;
    setIsSending(true);

    if (!campaignId) {
      setError("Invalid campaign. Please try again.");
      setIsSending(false);
      return;
    }

    if (!messageText.trim() && !attachment) {
      setError(
        "Cannot send an empty message. Please add text or an attachment."
      );
      setIsSending(false);
      return;
    }

    if (messageText.length > MAX_TEXT_LENGTH) {
      setError("Message exceeds the maximum allowed length of 1500 characters");
      setIsSending(false);
      return;
    }

    try {
      await sendMessage(messageText, attachment || undefined);
      setMessageText("");
      handleRemoveAttachment();
    } catch (err) {
      console.error("Failed to send message", err);
      setError("Failed to send message. Please try again.");
    } finally {
      await fetchMessages();
      setIsSending(false);
    }
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setError(null);
  };

  return {
    messageText,
    setMessageText,
    attachment,
    previewUrl,
    error,
    handleCloseError,
    handleAttachmentChange,
    handleRemoveAttachment,
    handleSend,
    handleEnterKeyPress,
    fileInputRef,
    textFieldRef,
    isSending, // Expose sending state if needed
  };
};

export default useMessageInput;

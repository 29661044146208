import {
  Card,
  Box,
  CardContent,
  Typography,
  Chip as MuiChip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Hit } from "instantsearch.js";
import axios from "axios";
import { formatWithCommas, isInFutureOrToday } from "../utils";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Logo from "./Logo";
import Icon, { ICONS_LIST } from "../Icon";
import { transformKeyToSvgName } from "../utils";
import IconChip from "./IconChip";

const ItemCard = ({ hit, favorites }: { hit: Hit; favorites: number[] }) => {
  const { slug } = hit;
  const [isFavorite, setIsFavorite] = useState(
    favorites.includes(parseInt(hit.objectID)),
  );

  useEffect(() => {
    setIsFavorite(favorites.includes(parseInt(hit.objectID)));
  }, [favorites]);

  const toggleFavorite = (e: any) => {
    e.preventDefault(); // Prevent the click from navigating to the site
    e.stopPropagation(); // Prevent the click from bubbling up to the parent card

    setIsFavorite(!isFavorite);

    axios({
      method: isFavorite ? "delete" : "post",
      url: `/api/v1/sites/${slug}/favorites`,
    });
  };

  return (
    <Card className="algolia-search__autocomplete-card">
      <Box display="flex" alignItems="flex-start">
        <Logo url={hit.logo_url} isVerified={hit["is_verified?"]} />
        <CardContent
          style={{ minWidth: 0, width: "100%", padding: "0px 0px 0 10px" }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <Typography
              variant="subtitle1"
              fontSize={20}
              fontWeight="500"
              color="#0E1951"
              className="algolia-search__autocomplete-title"
            >
              {hit.name}
            </Typography>

            <Box display="flex" ml="auto" alignItems="center">
              {!!hit.max_discount && (
                <MuiChip
                  label={`-${hit.max_discount}%`}
                  size="small"
                  sx={{
                    ".MuiChip-label": { px: "14px" },
                    bgcolor: "#3DB95F",
                    color: "#fff",
                    height: "20px",
                    fontWeight: 500,
                  }}
                />
              )}

              <FavoriteBorderIcon
                onClick={toggleFavorite}
                sx={{
                  ml: 2,
                  color: isFavorite ? "primary.dark" : "#A9ADC9",
                  opacity: isFavorite ? 1 : 0.4,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </Box>

          <Box display="flex" gap={1} alignItems="center" position="relative">
            {hit.category_names && hit.category_names.length > 0 && (
              <IconChip
                icon={
                  <Icon
                    name={
                      transformKeyToSvgName(
                        hit.category_names[0],
                      ) as keyof typeof ICONS_LIST
                    }
                  />
                }
                label={hit.category_names}
                textColor="#0e63f4"
              />
            )}
            {!!hit.list_size && (
              <>
                <IconChip
                  icon={<Icon name={"subscribers"} />}
                  label={formatWithCommas(hit.list_size)}
                />
              </>
            )}
            {hit["in_demand?"] && (
              <MuiChip
                label="In Demand"
                sx={{
                  bgcolor: "rgba(220, 53, 69, 0.07)",
                  color: "#DC3545",
                  height: "24px",
                  fontWeight: 500,
                  borderRadius: "20px",
                }}
              />
            )}

            {hit.search_badge_text &&
              isInFutureOrToday(hit.search_badge_end_date) && (
                <MuiChip
                  label={hit.search_badge_text}
                  sx={{
                    bgcolor: `${hit.search_badge_background_color}`,
                    color: `${hit.search_badge_text_color}`,
                    height: "24px",
                    fontWeight: 500,
                    borderRadius: "20px",
                  }}
                />
              )}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ItemCard;

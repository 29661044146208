import { AttachFile, Send } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import useMessageInput from "../hooks/useMessageInput";
import AttachmentPreview from "./AttachmentPreview";

const MessageInput: React.FC = () => {
  const {
    messageText,
    setMessageText,
    attachment,
    previewUrl,
    error,
    handleCloseError,
    handleAttachmentChange,
    handleRemoveAttachment,
    handleSend,
    handleEnterKeyPress,
    fileInputRef,
    textFieldRef,
    isSending,
  } = useMessageInput();

  const startAdornment = (
    <IconButton
      size="small"
      component="label"
      aria-label="Attach file"
      disabled={isSending}
    >
      <AttachFile />
      <input
        type="file"
        hidden
        onChange={handleAttachmentChange}
        ref={fileInputRef}
        accept=".gif,.jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.csv,"
      />
    </IconButton>
  );

  const endAdornment = (
    <Button
      disabled={isSending}
      onClick={handleSend}
      variant="contained"
      sx={{
        boxShadow: "none",
        ml: 1.5,
        minWidth: "40px",
        minHeight: "40px",
        p: 0,
        borderRadius: "8px",
      }}
      aria-label="Send message"
    >
      <Send sx={{ fontSize: "1.25rem" }} />
    </Button>
  );

  const InputProps = {
    disableUnderline: true,
    sx: {
      py: 1,
      minHeight: "55px",
      borderRadius: 1,
      bgcolor: "white",
      "& .MuiInputBase-input": {
        padding: "8px",
      },
      "&.Mui-focused, &:hover": {
        bgcolor: "transparent",
      },
    },
    startAdornment: startAdornment,
    endAdornment: endAdornment,
  };

  return (
    <Box width="100%" mb={"-1px"}>
      {/* Attachment Preview */}
      {attachment && (
        <AttachmentPreview
          attachment={attachment}
          previewUrl={previewUrl}
          onRemove={handleRemoveAttachment}
        />
      )}

      {/* Input Field */}
      <Box
        display="flex"
        alignItems="center"
        borderTop={`1px solid ${grey[300]}`}
        bgcolor="white"
      >
        <TextField
          disabled={isSending}
          inputRef={textFieldRef}
          variant="filled"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Write a message..."
          onKeyDown={handleEnterKeyPress}
          InputProps={InputProps}
        />
      </Box>

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MessageInput;

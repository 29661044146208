import React, { useLayoutEffect, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Users from "./pages/users";
import SignInForm from "./pages/users/sign-in";
import SignInPasswordForm from "./pages/users/sign-in/SignInPasswordForm";
import TwoFactorAuthentication from "./pages/users/sign-in/TwoFactorAuthentication";
import SignUpForm from "./pages/users/sign-up";
import Publisher from "./pages/publisher/sites";
import AddSite from "./pages/publisher/add-site";
import PreviousAdvertisers from "./pages/publisher/add-site/previousAdvertisers";
import ProfileReview from "./pages/publisher/add-site/profileReview";
import Layout from "./components/layout";
import Overview from "./pages/publisher/add-site/overview";
import SponsoredEmail from "./pages/publisher/add-site/sponsoredEmail";
import DedicatedEmail from "./pages/publisher/add-site/dedicatedEmail";
import BlogPosts from "./pages/publisher/add-site/sponsoredPost";
import Payments from "./pages/publisher/payments";

import Campaigns from "./pages/advertiser/campaigns";
import CreativeEdit from "./pages/advertiser/campaigns/Creative";
import Marketplace from "./pages/marketplace";

import "react-image-crop/dist/ReactCrop.css";
import axios, { AxiosError, AxiosResponse } from "axios";
import Snackbar from "@mui/material/Snackbar";
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { handleAuth, setAuthCookies } from "./utils/auth";
import { Base64 } from "js-base64";
import CompanyDetailsForm from "./pages/users/sign-up/signupForms/CompanyDetailsForm";
import GoalsAndBudgetForm from "./pages/users/sign-up/signupForms/GoalsAndBudgetForm";
import Submitted from "./pages/users/sign-up/Submitted";
import UserDetailsForm from "./pages/users/sign-up/signupForms/UserDetailsForm";
import PhoneVerifyForm from "./pages/users/sign-up/signupForms/PhoneVerifyForm";
import CodeVerificationForm from "./pages/users/sign-up/signupForms/CodeVerificationForm";
import NewsLetterDetailsForm from "./pages/users/sign-up/signupForms/NewsLetterDetailsForm";
import SchedulePage from "pages/publisher/add-site/schedule";
import { InboxChat } from "pages/chats";
import ChatWindow from "pages/chats/components/ChatWindow";
import SponsorshipDetails from "pages/chats/components/SponsorshipDetails";

let BEARER_EXPIRES = Number(Cookies.get("bearer_expires") ?? 0);
const ONE_MINUTE = 60000;
const ONE_DAY = 24 * 60 * ONE_MINUTE;

// DO NOT REMOVE!!!! WE'LL USE IT IN THE FUTURE!!!!
// eslint-disable-next-line
function refreshBearer() {
  const currentTime = Date.now();
  const delta = BEARER_EXPIRES - currentTime;

  // TODO: discuss whether we need cases when the token corruption time is less than 5 minutes
  if (delta < 0 || delta > 5 * ONE_MINUTE) {
    return;
  }

  // Avoid more refreshes
  BEARER_EXPIRES = Date.now() + ONE_DAY;

  axios
    .post("/api/v1/oauth/token", {
      grant_type: "refresh_token",
      refresh_token: Cookies.get("bearer_refresh"),
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    })
    .then((response) => {
      handleAuth(response);
      BEARER_EXPIRES = Number(Cookies.get("bearer_expires") ?? 0);
    });
}

function setAuthData(): void {
  const bearer = Cookies.get("bearer");

  if (bearer) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${bearer}`;
    return;
  }

  const rasuwa = Cookies.get("rasuwa");

  if (!rasuwa) {
    return;
  }

  try {
    const {
      t: access_token,
      e: expires_in,
      r: refresh_token,
    } = JSON.parse(Base64.decode(rasuwa));

    setAuthCookies(access_token, expires_in, refresh_token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    Cookies.remove("rasuwa");
  } catch (e) {
    console.error(e);
    return;
  }
}

export default function App() {
  const [error, setError] = useState<string | null>(null);

  let location = useLocation();

  useEffect(() => {
    setError(null);
  }, [location]);

  useLayoutEffect(() => {
    axios.defaults.responseType = "json";

    setAuthData();

    /*
    Refresh has conflicts with static pages. Uncomment when all pages are SPA.
    DO NOT REMOVE!!!
    axios.interceptors.request.use((request) => {
      refreshBearer();
      return request;
    });*/

    axios.interceptors.response.use(
      (axiosResponse: AxiosResponse) => axiosResponse,
      (
        axiosError: AxiosError<{
          location?: string;
          errors?: string[] | { [key: string]: string };
        }>
      ) => {
        const status = axiosError.response?.status;
        const location = axiosError.response?.data?.location;
        let errors: { [key: string]: string } | string | string[] | undefined;

        if (axiosError.code === "ERR_NETWORK") {
          errors = [
            "Can't connect to the site. Please check your internet connection.",
          ];
        } else if (status === 429) {
          errors = ["Too many requests. Please try again later"];
        } else {
          errors = axiosError.response?.data?.errors;
        }

        if (status === 403 && !!location) {
          window.location.href = location;
        }

        if (status === 401 && window.location.pathname !== "/users/sign_in") {
          window.location.pathname = "/users/sign_in";
        }

        const accessDenied = status === 403 || status === 401;

        if (
          status &&
          axiosError.config.processErrorInComponentStatuses?.includes(status)
        ) {
          return Promise.reject(axiosError);
        }

        if (!accessDenied && !errors) {
          setError("Something went wrong.");
        }

        if (Array.isArray(errors)) {
          setError(errors[0]);
        }

        return Promise.reject(axiosError);
      }
    );
  }, []);

  return (
    <>
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => null}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            variant="filled"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              "& .MuiAlert-message": {
                paddingTop: "10px",
                paddingBottom: "10px",
              },
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
      <Routes>
        <Route path="/users/" element={<Users />}>
          <Route path="sign_in" element={<SignInForm />} />
          <Route path="sign_in/password" element={<SignInPasswordForm />} />
          <Route
            path="sign_in/two_factor_authentication"
            element={<TwoFactorAuthentication />}
          />
          <Route path="sign_up" element={<SignUpForm />}>
            <Route path="" element={<UserDetailsForm fullForm={true} />} />
          </Route>
          <Route path="onboarding/" element={<SignUpForm />}>
            {/* Common */}
            <Route
              path="account_details"
              element={<UserDetailsForm fullForm={false} />}
            />
            <Route path="company_details" element={<CompanyDetailsForm />} />

            {/* Advertiser */}
            <Route path="goals" element={<GoalsAndBudgetForm />} />
            <Route path="complete" element={<Submitted />} />

            {/* Publisher */}
            <Route path="phone_number" element={<PhoneVerifyForm />} />
            <Route path="check_code" element={<CodeVerificationForm />} />
            <Route
              path="newsletter_details"
              element={<NewsLetterDetailsForm />}
            />
          </Route>
        </Route>
        <Route path="/" element={<Layout />}>
          <Route path="advertiser/campaigns/" element={<Campaigns />}>
            <Route
              path=":campaignId/creative/edit"
              element={<CreativeEdit />}
            />
          </Route>
          <Route path="sponsorships" element={<InboxChat />}>
            <Route path=":campaignId" element={<ChatWindow />}>
              <Route path="details" element={<SponsorshipDetails />} />
            </Route>
          </Route>
          <Route path="publisher/payments" element={<Payments />} />
          <Route path="publisher/sites" element={<Publisher />} />
          <Route path="publisher/sites" element={<AddSite />}>
            <Route path="new" element={<Overview />} />
            <Route path=":siteSlug/edit" element={<Overview />} />
            <Route
              path=":siteSlug/edit/previous_advertisers"
              element={<PreviousAdvertisers />}
            />
            <Route
              path=":siteSlug/edit/sponsored_email"
              element={<SponsoredEmail />}
            />
            <Route
              path=":siteSlug/edit/dedicated_email"
              element={<DedicatedEmail />}
            />
            <Route
              path=":siteSlug/edit/sponsored_post"
              element={<BlogPosts />}
            />
            <Route path=":siteSlug/schedule" element={<SchedulePage />} />
            {/*blog_posts deprecated*/}
            <Route path=":siteSlug/edit/blog_posts" element={<BlogPosts />} />
            <Route path=":siteSlug/edit/review" element={<ProfileReview />} />
          </Route>
          <Route path="marketplace" element={<Marketplace />} />
          <Route path="marketplace/rebookings" element={<Marketplace />} />
          <Route path="collections/:siteSlug" element={<Marketplace />} />
          <Route path="categories/:siteSlug" element={<Marketplace />} />
          <Route path="search/advertiser_search" element={<Marketplace />} />
          <Route path="search" element={<Marketplace />} />
        </Route>
      </Routes>
    </>
  );
}

// chats/utils/fileUtils.ts

import {
  Description,
  Image,
  InsertDriveFile,
  PictureAsPdf,
  TableChart,
} from "@mui/icons-material";

// Define supported file types and their corresponding icons
export const SUPPORTED_FILE_TYPES: Record<string, JSX.Element> = {
  // Images
  "image/gif": <Image />,
  "image/jpeg": <Image />,
  "image/jpg": <Image />,
  "image/png": <Image />,

  // Documents
  "application/pdf": <PictureAsPdf />,
  "application/msword": <Description />,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <Description />
  ),

  // Spreadsheets
  "application/vnd.ms-excel": <TableChart />,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
    <TableChart />
  ),
  "text/csv": <TableChart />,
};

// Maximum file size in bytes (20 MB)
export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB

export const isSupportedFileType = (fileType: string): boolean => {
  return Object.keys(SUPPORTED_FILE_TYPES).includes(fileType);
};

// Function to get the appropriate icon based on file type
export const getFileIcon = (fileType: string): JSX.Element => {
  return SUPPORTED_FILE_TYPES[fileType] || <InsertDriveFile />;
};

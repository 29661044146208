import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Campaign } from "../types/types";

/**
 * Hook to manage campaign selection and filter popover logic.
 */
const useCampaignList = () => {
  const { campaignId } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCampaignClick = (campaign: Campaign) => {
    if (campaignId !== campaign.uuid) {
      navigate(`/sponsorships/${campaign.uuid}`);
    }
  };

  return {
    anchorEl,
    setAnchorEl,
    handleClosePopover,
    handleCampaignClick,
  };
};

export default useCampaignList;

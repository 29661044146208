import { Box, List, Typography } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import React from "react";
import { useParams } from "react-router-dom";
import { useChat } from "../context/ChatContext";
import useCampaignList from "../hooks/useCampaignList";
import { Campaign } from "../types/types";
import CampaignItem from "./CampaignItem";
import SkeletonLoader from "./SkeletonLoader";

const CampaignList: React.FC = () => {
  const { campaignId } = useParams();
  const { handleCampaignClick } = useCampaignList();
  const {
    state: { loading },
    filteredCampaigns,
  } = useChat();

  return (
    <Box
      maxWidth={{
        xs: "100%",
        md: campaignId ? "25%" : "100%",
        lg: campaignId ? "350px" : "100%",
      }}
      minWidth={{ lg: "350px" }}
      sx={{
        overflowY: "hidden",
        backgroundColor: common.white,
        flexGrow: 1,
        borderRadius: "12px",
        border: `1px solid ${grey[300]}`,
      }}
    >
      {/**TODO: enable once filters are implemented on BE */}
      {/* <SearchBar />
      <QuickFilters /> */}
      <List
        disablePadding
        sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
      >
        {loading && <SkeletonLoader count={8} />}

        {!loading && filteredCampaigns.length === 0 && (
          <Box p={2} textAlign="center">
            <Typography variant="body1" color="text.secondary">
              No campaigns found. Try adjusting your filters.
            </Typography>
          </Box>
        )}

        {!loading &&
          filteredCampaigns.length > 0 &&
          filteredCampaigns.map((campaign: Campaign) => (
            <CampaignItem
              key={campaign.uuid}
              campaign={campaign}
              selected={campaignId === campaign.uuid}
              onClick={() => handleCampaignClick(campaign)}
            />
          ))}
      </List>
    </Box>
  );
};

export default CampaignList;

import React from "react";
import { ChatProvider } from "../context/ChatContext";
import InboxChatContent from "./InboxChatContent";

const InboxChat: React.FC = () => {
  return (
    <ChatProvider>
      <InboxChatContent />
    </ChatProvider>
  );
};

export default InboxChat;

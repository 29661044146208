import { ChatAction, ChatActions } from "../types/chatActions";
import { ChatState } from "../types/types";

export const initialState: ChatState = {
  campaigns: [],
  messages: {
    messages: [],
    statuses: [],
  },
  filters: {
    sponsorshipType: [],
    status: [],
  },
  loading: false,
  messagesLoading: false,
  messagesPolling: false,
};

export function reducer(state: ChatState, action: ChatAction): ChatState {
  switch (action.type) {
    case ChatActions.FETCH_CAMPAIGNS_REQUEST:
      return { ...state, loading: true };
    case ChatActions.FETCH_CAMPAIGNS_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case ChatActions.FETCH_CAMPAIGNS_FAILURE:
      return { ...state, loading: false };
    case ChatActions.FETCH_MESSAGES_REQUEST:
      return action.payload.isPolling
        ? { ...state, messagesPolling: true }
        : { ...state, messagesLoading: true };
    case ChatActions.FETCH_MESSAGES_SUCCESS:
      return action.payload.isPolling
        ? {
            ...state,
            messagesPolling: false,
            messages: action.payload.messages,
          }
        : {
            ...state,
            messagesLoading: false,
            messages: action.payload.messages,
          };
    case ChatActions.FETCH_MESSAGES_FAILURE:
      return action.payload.isPolling
        ? { ...state, messagesPolling: false }
        : { ...state, messagesLoading: false };
    case ChatActions.SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case ChatActions.MARK_CAMPAIGN_AS_READ:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) =>
          campaign.uuid === action.payload
            ? { ...campaign, unread_count: 0 }
            : campaign
        ),
      };
    case ChatActions.MARK_CAMPAIGN_AS_UNREAD:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) =>
          campaign.uuid === action.payload
            ? { ...campaign, unread_count: 1 }
            : campaign
        ),
      };
    case ChatActions.SEND_MESSAGE: {
      const updatedMessages = state.messages
        ? {
            ...state.messages,
            messages: [...state.messages.messages!, action.payload],
          }
        : { messages: [action.payload], statuses: [] };
      return { ...state, messages: updatedMessages };
    }
    default:
      return state;
  }
}

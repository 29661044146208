import {
  Close,
  Description,
  Image,
  InsertDriveFile,
  PictureAsPdf,
  TableChart,
} from "@mui/icons-material";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

const SUPPORTED_FILE_TYPES: Record<string, JSX.Element> = {
  // Images
  "image/gif": <Image />,
  "image/jpeg": <Image />,
  "image/jpg": <Image />,

  // Documents
  "application/pdf": <PictureAsPdf />,
  "application/msword": <Description />,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <Description />
  ),

  // Spreadsheets
  "application/vnd.ms-excel": <TableChart />,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
    <TableChart />
  ),
  "text/csv": <TableChart />,
};

const getFileIcon = (fileType: string): JSX.Element => {
  return SUPPORTED_FILE_TYPES[fileType] || <InsertDriveFile />;
};

interface AttachmentPreviewProps {
  attachment: File;
  previewUrl: string | null;
  onRemove: () => void;
}

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({
  attachment,
  previewUrl,
  onRemove,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        bgcolor: grey[100],
        borderColor: grey[300],
        borderRadius: 1,
      }}
    >
      {previewUrl ? (
        // Image Attachment Preview
        <Box position="relative" width="50px" height="50px" mr={2}>
          <img
            src={previewUrl}
            alt="Attachment Preview"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              objectFit: "cover",
            }}
          />
          <IconButton
            size="small"
            onClick={onRemove}
            sx={{
              position: "absolute",
              top: -10,
              right: -10,
              backgroundColor: "rgba(255,255,255,0.8)",
              "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
            }}
            aria-label="Remove attachment"
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        // Non-Image Attachment Display
        <Box display="flex" alignItems="center" flexGrow={1}>
          {getFileIcon(attachment.type)}
          <Tooltip title={attachment.name}>
            <Typography
              variant="body2"
              noWrap
              sx={{ ml: 1, maxWidth: "200px" }}
            >
              {attachment.name}
            </Typography>
          </Tooltip>
          <IconButton
            size="small"
            onClick={onRemove}
            sx={{ ml: 1 }}
            aria-label="Remove attachment"
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Paper>
  );
};

export default AttachmentPreview;

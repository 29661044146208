import axios from "axios";
import { Messages, Campaign, Message } from "../types/types";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const API_BASE = "/api/v1/chats";

export const getCampaigns = async (): Promise<Campaign[]> => {
  const response = await axios.get<Campaign[]>(`${API_BASE}`);
  return response.data;
};

export const getMessages = async (campaignUuid: string): Promise<Messages> => {
  const response = await axios.get<Messages>(`${API_BASE}/${campaignUuid}`);
  return response.data;
};

export const sendMessage = async (
  campaignUuid: string,
  message: string,
  attachment?: File
): Promise<Message> => {
  if (!campaignUuid) {
    throw new Error("Campaign UUID is required");
  }
  if (attachment) {
    if (attachment.size > MAX_FILE_SIZE) {
      throw new Error("File size exceeds 10MB limit");
    }
  }
  const formData = new FormData();
  formData.append("message", message);
  if (attachment) {
    formData.append("attachment", attachment, attachment.name);
  }
  const controller = new AbortController();
  try {
    const response = await axios.post<Message>(
      `${API_BASE}/${campaignUuid}/create`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal: controller.signal,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(`Failed to send message`);
    }
    throw error;
  }
};

export const markAsRead = async (campaignUuid: string): Promise<void> => {
  await axios.patch(`${API_BASE}/${campaignUuid}/mark_as_read`);
};

export const markAsUnread = async (campaignUuid: string): Promise<void> => {
  await axios.patch(`${API_BASE}/${campaignUuid}/mark_as_unread`);
};

import { formatDistanceToNow } from "date-fns";

function formatTimeDistanceShort(date: Date): string {
  const distance = formatDistanceToNow(date, {
    includeSeconds: true,
    addSuffix: true,
  });

  const unwantedWords: string[] = ["about", "less", "than", "almost", "over"];

  const replacements: Record<string, string> = {
    day: "d",
    days: "d",
    hour: "h",
    hours: "h",
    minute: "m",
    minutes: "m",
    second: "s",
    seconds: "s",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  const words = distance
    .split(" ")
    .filter((word) => !unwantedWords.includes(word));

  const result: string[] = [];
  for (let i = 0; i < words.length; i++) {
    if (!isNaN(Number(words[i])) && replacements[words[i + 1]]) {
      result.push(words[i] + replacements[words[i + 1]]);
      i++;
    } else {
      result.push(words[i]);
    }
  }

  return result.join(" ");
}

export default formatTimeDistanceShort;

import {
  Avatar,
  Badge,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { common } from "@mui/material/colors";
import { format, fromUnixTime } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import useCampaignStatusColor from "../hooks/useCampaignStatusColor";
import formatTimeDistanceShort from "../types/formatTimeDistanceShort";
import { Campaign } from "../types/types";
import { getDeadlineColor } from "../utils/deadlineColor";

interface CampaignItemProps {
  campaign: Campaign;
  selected: boolean;
  onClick: () => void;
}

const CampaignItem: React.FC<CampaignItemProps> = React.memo(
  ({ campaign, selected, onClick }) => {
    const { campaignId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const statusColor = useCampaignStatusColor(campaign.sponsorship.status);
    const isCampaignSelected = Boolean(campaignId);

    const formattedRunDate = campaign.sponsorship.run_date
      ? format(new Date(campaign.sponsorship.run_date), "EEE, MMM d, yyyy")
      : null;

    const formattedDeadline = campaign.sponsorship.deadline
      ? format(fromUnixTime(campaign.sponsorship.deadline), "EEE, MMM d, yyyy")
      : null;

    const timeSinceLatestMessage = campaign.latest_message_time
      ? formatTimeDistanceShort(fromUnixTime(campaign.latest_message_time))
      : null;

    const secondaryDetails = [
      {
        label: null,
        value: campaign.sponsorship.name
          ? `${campaign.sponsorship.type} - ${campaign.sponsorship.name}`
          : campaign.sponsorship.type,
        condition: Boolean(campaign.sponsorship.type),
      },
      {
        label: "Deadline",
        value: formattedDeadline,
        color: getDeadlineColor(campaign.sponsorship.deadline),
        condition: Boolean(formattedDeadline),
      },
      {
        label: "Status",
        value: campaign.sponsorship.status,
        condition:
          !campaignId && !isMobile && Boolean(campaign.sponsorship.status),
      },
      {
        label: "Run Date",
        value: formattedRunDate,
        condition:
          ((!isCampaignSelected && !isMobile) || !formattedDeadline) &&
          Boolean(formattedRunDate),
      },
    ];

    const avatarJsx = (
      <Badge
        overlap="rectangular"
        badgeContent=" "
        color={statusColor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiBadge-badge": {
            left: "7px",
            bottom: "5px",
            border: "4px solid #fff",
          },
        }}
      >
        <Avatar
          src={campaign.profile.logo}
          alt={campaign.profile.name}
          sx={{ width: 56, height: 56, borderRadius: 1 }}
        />
      </Badge>
    );

    const primaryTextJsx = (
      <Stack direction="row" alignItems="center" gap={1} ml={1} height={"20px"}>
        <Typography variant="caption" fontWeight="bold" noWrap>
          {campaign.profile.name}
        </Typography>
        {campaign.unread_count > 0 && (
          <Chip
            label={campaign.unread_count}
            size="small"
            sx={{
              backgroundColor: "primary.main",
              color: common.white,
              fontSize: 12,
              borderRadius: "4px",
              fontWeight: "bold",
              height: "18px",
              "& .MuiChip-label": { padding: 0.5 },
            }}
          />
        )}
        <Stack flexGrow={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ marginLeft: "auto" }}
            noWrap
          >
            {timeSinceLatestMessage}
          </Typography>
        </Stack>
      </Stack>
    );

    const secondaryTextJsx = (
      <Stack
        pl={1}
        spacing={isMobile || isCampaignSelected ? 0 : 1}
        direction={isMobile || isCampaignSelected ? "column" : "row"}
      >
        {secondaryDetails.map(({ label, value, color, condition }, index) => (
          <Stack
            key={index}
            width={isMobile || isCampaignSelected ? "100%" : "25%"}
          >
            {condition && (
              <Typography variant="caption" color="text.disabled" noWrap>
                {label ? label + ": " : ""}
                <Typography
                  variant="caption"
                  color={color || "text.disabled"}
                  noWrap
                >
                  {value}
                </Typography>
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
    );

    return (
      <ListItem
        onClick={onClick}
        selected={selected}
        role="button"
        sx={{
          height: "80px",
          borderBottom: "1px solid #F1F1F1",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
          },
          display: "flex",
        }}
      >
        <ListItemAvatar>{avatarJsx}</ListItemAvatar>
        <ListItemText disableTypography sx={{ my: 0 }}>
          <Stack direction="column" height="56px">
            {primaryTextJsx}
            {secondaryTextJsx}
          </Stack>
        </ListItemText>
      </ListItem>
    );
  }
);

export default CampaignItem;

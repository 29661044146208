export const toCamel = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const formatWithCommas = (number: number | string) => {
  return Number(number).toLocaleString("en-US");
};

export const isInFutureOrToday = (dateString: string | null) => {
  if (!dateString) return true;
  const givenDate = new Date(dateString);
  givenDate.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return givenDate.getTime() >= today.getTime();
};

export const transformKeyToSvgName = (key: string) => {
  return key
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, " ") // Replace special characters (like &) with spaces
    .replace(/(?:\s|-)(\w)/g, (_, letter) => letter.toUpperCase()) // Capitalize the first letter after spaces or dashes
    .replace(/[\s-]/g, ""); // Remove remaining spaces and dashes
};

import React from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Box,
} from "@mui/material";

const SkeletonLoader: React.FC<{ count: number }> = ({ count }) => (
  <>
    {Array.from({ length: count }).map((_, index) => (
      <ListItem key={index} sx={{ p: 1, borderBottom: "1px solid #F1F1F1" }}>
        <ListItemAvatar>
          <Skeleton variant="rounded" width={56} height={56} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Stack direction="row" alignItems="center" gap={1} ml={1}>
              <Skeleton variant="text" width="60%" height={20} />
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width={20}
                  height={20}
                  sx={{ borderRadius: "4px" }}
                />
                <Skeleton variant="text" width={40} height={20} />
              </Box>
            </Stack>
          }
          secondary={
            <Stack pl={1} spacing={0.5}>
              <Skeleton variant="text" width="80%" height={16} />
              <Skeleton variant="text" width="50%" height={16} />
            </Stack>
          }
        />
      </ListItem>
    ))}
  </>
);

export default SkeletonLoader;

import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Outlet, useParams } from "react-router-dom";

import CampaignList from "./CampaignList";
import OptOutBanner from "./OptOutBanner";

const MobileView: React.FC<{ campaignId?: string }> = ({ campaignId }) => {
  if (campaignId) {
    return <Outlet />;
  }
  return <CampaignList />;
};

const DesktopView: React.FC<{ campaignId?: string }> = ({ campaignId }) => {
  return (
    <>
      <CampaignList />
      {campaignId && <Outlet />}
    </>
  );
};

const InboxChatContent: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { campaignId } = useParams<{ campaignId: string }>();

  return (
    <>
      <OptOutBanner />
      <Box display="flex" height={"calc(100svh - 104px)"} gap={1.5}>
        {isMobile ? (
          <MobileView campaignId={campaignId} />
        ) : (
          <DesktopView campaignId={campaignId} />
        )}
      </Box>
    </>
  );
};

export default InboxChatContent;

import Slider from "./Slider";
import { SyntheticEvent, useEffect, useState } from "react";
import { RangeInputProps, useRange } from "react-instantsearch";

const MAX = 1000000;
const MIN = 0;
const STEP = 10000;

const getValue = (start: number[]) => [
  Number.isFinite(start[0]) ? start[0] : MIN,
  Number.isFinite(start[1]) ? start[1] : MAX,
];

export default function SubscriberSlider(props: RangeInputProps) {
  const { start, refine } = useRange({ ...props, min: MIN });
  const [value, setValue] = useState(getValue(start as number[]));

  useEffect(() => {
    setValue(getValue(start as number[]));
  }, [start]);

  const handleChange = (e: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleChangeCommited = (
    e: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    const [min, max] = value as number[];
    refine([min, max === MAX ? undefined : max]);
  };

  return (
    <Slider
      value={value}
      min={MIN}
      max={MAX}
      step={STEP}
      handleChange={handleChange}
      handleChangeCommitted={handleChangeCommited}
    />
  );
}

import {
  ChevronLeft,
  FindInPageOutlined,
  MoreVert,
  PreviewOutlined,
} from "@mui/icons-material";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { format } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useChat } from "../context/ChatContext";
import useChatHeaderMenu from "../hooks/useChatHeaderMenu";

const ChatHeader = () => {
  const {
    state: { loading },
    selectedCampaign,
  } = useChat();
  const location = useLocation();
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const isDetailsPage = location.pathname.endsWith("details");
  const {
    anchorEl,
    open,
    handleMenuOpen,
    handleMenuClose,
    handleMarkAsUnread,
    handleViewSponsorship,
    handleViewDetails,
  } = useChatHeaderMenu();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const backButton = (
    <Button
      sx={{ minWidth: "40px", height: "40px" }}
      onClick={() => navigate(-1)}
    >
      <ChevronLeft />
    </Button>
  );

  const LoadingSkeleton = (
    <Box display="flex" alignItems="center" gap={1}>
      <Skeleton variant="rectangular" width={40} height={40} />
      <Skeleton variant="text" width={200} />
    </Box>
  );

  const campaignAvatar = (
    <Avatar
      src={selectedCampaign?.profile.logo}
      alt={selectedCampaign?.profile.name}
      sx={{
        width: 40,
        height: 40,
        mr: 1.5,
        borderRadius: 1,
        flexShrink: 0,
      }}
    />
  );

  const campaignInfo = (
    <Box
      display="flex"
      flexDirection="column"
      gap="4px"
      sx={{ flex: 1, minWidth: 0 }}
    >
      <Typography
        variant="subtitle1"
        fontWeight={600}
        noWrap
        sx={{
          fontSize: "14px",
          lineHeight: "16px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {selectedCampaign?.profile.name}
      </Typography>
      <Chip
        label={selectedCampaign?.sponsorship.status}
        size="small"
        sx={{
          mr: "auto",
          height: "20px",
          bgcolor: blue[50],
          color: "primary.main",
        }}
      />
    </Box>
  );

  const actionMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      elevation={1}
      onClose={handleMenuClose}
      sx={{ borderRadius: "8px" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={handleViewDetails}>
        <FindInPageOutlined sx={{ mr: 1, color: "text.disabled" }} />
        Overview
      </MenuItem>
      <MenuItem onClick={handleMarkAsUnread}>
        <MarkEmailUnreadOutlinedIcon sx={{ mr: 1, color: "text.disabled" }} />
        Mark as Unread
      </MenuItem>
      <MenuItem onClick={handleViewSponsorship}>
        <PreviewOutlined sx={{ mr: 1, color: "text.disabled" }} />
        View Sponsorship
      </MenuItem>
    </Menu>
  );

  const sponsorshipDetails = campaignId && (
    <Typography variant="body2" sx={{ fontSize: "14px", lineHeight: "16px" }}>
      {`${selectedCampaign?.sponsorship?.type ?? ""} ${
        selectedCampaign?.sponsorship?.name
          ? `- ${selectedCampaign?.sponsorship.name}`
          : ""
      }`}
    </Typography>
  );

  const sponsorshipDate = selectedCampaign?.sponsorship.run_date && (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ fontSize: "0.875rem" }}
    >
      {format(
        new Date(selectedCampaign.sponsorship.run_date),
        "EEEE, MMMM d, yyyy"
      )}
    </Typography>
  );

  const sponsorshipInfo = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      ml={{ xs: "92px", sm: 0 }}
    >
      {sponsorshipDetails}
      {sponsorshipDate}
    </Box>
  );

  const markAsUnreadButton = (
    <Tooltip title="Mark as unread">
      <Button
        variant="outlined"
        size="small"
        disableRipple
        sx={{ minWidth: "unset" }}
        color="inherit"
        onClick={handleMarkAsUnread}
      >
        <MarkEmailUnreadOutlinedIcon sx={{ color: "#BCC4DA" }} />
      </Button>
    </Tooltip>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      p={1}
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "16px 16px 0 0",
        height: "56px",
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box
          display="flex"
          alignItems="center"
          minWidth={0}
          overflow="hidden"
          flexGrow={{ xs: 1, sm: 0 }}
        >
          {!isMediumScreen && backButton}
          {!selectedCampaign && loading && LoadingSkeleton}
          {selectedCampaign && campaignAvatar}
          {selectedCampaign && campaignInfo}
          {isMobile && !isDetailsPage && (
            <Button
              size="small"
              disableRipple
              variant="outlined"
              color="inherit"
              sx={{ minWidth: "40px", p: 0, height: "40px", ml: "auto" }}
              onClick={handleMenuOpen}
            >
              <MoreVert />
            </Button>
          )}
        </Box>
        {isMobile && actionMenu}
        {!isMobile && sponsorshipInfo}
        {!isMobile && selectedCampaign && markAsUnreadButton}
      </Box>
      {isMobile && actionMenu}
    </Box>
  );
};

export default ChatHeader;

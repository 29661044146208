import React from "react";
import { Box, Avatar } from "@mui/material";
import Icon from "../Icon";

const VerifiedBadge = () => (
  <Box
    sx={{
      position: "absolute",
      bottom: "-4px",
      right: "-4px",
      width: 22,
      height: 22,
    }}
  >
    <Icon name="verifiedStats" fill="#0E63F4" />
  </Box>
);
const Logo = ({ url, isVerified }: { url: string; isVerified: boolean }) => (
  <Box position="relative">
    <Avatar alt="Logo" src={url} sx={{ width: 40, height: 40 }} />
    {isVerified && <VerifiedBadge />}
  </Box>
);

export default Logo;

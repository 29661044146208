import "./categories.scss";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import axios from "axios";
import { useState, useEffect, useContext, ReactNode } from "react";
import { PopupButton } from "@typeform/embed-react";
import { Link as MuiLink } from "@mui/material";
import { UserContext } from "../../../context";
import typeformImg from "../../../assets/images/typeform.png";
import { UserType } from "pages/users/sign-up/types";
import { useNavigate } from "react-router-dom";

type Category = {
  body: string | ReactNode;
  image: string | null;
  link?: string;
  title: string;
};

type KeyCategory = {
  link: string;
  title: string;
  short_description: string;
  slug: string;
  image: string | null;
};

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  customPaging: function () {
    return <span className="dot" />;
  },
  arrows: false,
  dots: true,
  speed: 600,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type CategoryContentProps = {
  image: string | null;
  title: string;
  body: string | React.ReactNode;
};

const CategoryContent: React.FC<CategoryContentProps> = ({
  image,
  title,
  body,
}) => (
  <>
    <div className="image">
      {image && <img src={image} width="100" height="156" alt={title} />}
    </div>
    <div className="text">
      <strong className="title">{title}</strong>
      <p>{body}</p>
    </div>
  </>
);

const getCurrentFullUrl = () =>
  `${window.location.pathname}${window.location.search}`;

// Don't set default value for a link parameter, it will raise an issue with typeform
const Item = ({ link, image, title, body }: Category) => {
  const navigate = useNavigate();

  const isInternalLink =
    link?.startsWith("/categories") || link?.startsWith("/collections");

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!link) {
      return;
    }

    const fullUrl = getCurrentFullUrl();
    navigate(link, {
      state: { from: fullUrl },
    });
  };

  return (
    <div className="item">
      {isInternalLink ? (
        <MuiLink onClick={handleNavigation} className="card">
          <CategoryContent image={image} title={title} body={body} />
        </MuiLink>
      ) : (
        <MuiLink href={link} className="card">
          <CategoryContent image={image} title={title} body={body} />
        </MuiLink>
      )}
    </div>
  );
};

const TypeFormWrapper = () => {
  const {
    userData: { ro_id, email = "", companyName, firstName },
  } = useContext(UserContext);

  return (
    <PopupButton
      className="typeform-button"
      id="W5j3F33Y"
      size={90}
      style={{ padding: 0, border: "none", background: "none" }}
      hidden={{
        email,
        first_name: firstName,
        user_id: String(ro_id),
        company_name: companyName,
      }}
      enableSandbox={false}
      transitiveSearchParams
    >
      <Item
        body="Let us help you find the best deals and discounts to maximize your budget."
        image={typeformImg}
        title="Request a Media Plan"
      />
    </PopupButton>
  );
};

export default function Categories() {
  const {
    userData: { userType },
  } = useContext(UserContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const [keyCategories, setKeyCategories] = useState<KeyCategory[]>([]);

  const isAdvertiser = userType === UserType.ADVERTISER;

  useEffect(() => {
    axios.get("/api/v1/featured_categories").then((response) => {
      setCategories(response.data);
    });

    axios.get("/api/v1/key_categories").then((response) => {
      setKeyCategories(response.data);
    });
  }, []);

  return (
    <section className="categories">
      <h3 className="heading">Marketplace</h3>
      {(keyCategories.length > 0 || categories.length > 0) && (
        <Slider {...settings} className="list carousel">
          {isAdvertiser && <TypeFormWrapper />}
          {categories.map((item, index) => (
            <Item {...item} key={`${item.title}_${index}}`} />
          ))}
          {keyCategories.map((category) => (
            <Item
              {...category}
              key={category.slug}
              body={category.short_description}
            />
          ))}
        </Slider>
      )}
    </section>
  );
}

import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Box,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchBox, useHits } from "react-instantsearch";
import { BaseHit, Hit } from "instantsearch.js";
import { useNavigate, Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classNames from "classnames";
import "./styles.scss";
import useSearchAnalytics from "../hooks/useSearchAnalytics";
import ItemCard from "./Card";
import axios from "axios";

const CustomAutoComplete = ({
  isEmbedded,
  relatedProducts,
}: {
  isEmbedded: boolean;
  relatedProducts?: Hit<BaseHit>[];
}) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { query: searchBoxQuery, refine } = useSearchBox();
  const { hits } = useHits();

  useSearchAnalytics(searchBoxQuery);

  const [favorites, setFavorites] = useState<number[]>([]);

  useEffect(() => {
    axios.get("/api/v1/sites/favorites").then((response) => {
      setFavorites(response.data);
    });
  }, []);

  const query = useMemo(() => {
    return searchBoxQuery.trim();
  }, [searchBoxQuery]);

  const currentHits = useMemo(() => {
    return hits.slice(0, 5);
  }, [hits]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.composedPath()[0] as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !!query) {
      textFieldRef.current?.blur();
      setOpen(false);
      if (isEmbedded) {
        window.location.href = `/marketplace?query=${encodeURIComponent(query)}`;
      } else {
        navigate(`/marketplace?query=${encodeURIComponent(query)}`);
      }
    }
  };

  const renderHits = (hits: Hit[]) => {
    if (hits.length > 0)
      return hits.map((hit) => (
        <Link
          onClick={() => {
            setOpen(false);
            textFieldRef.current?.blur();
          }}
          to={`/sites/${hit.slug}?attr_source=top-nav`}
          key={hit.objectID}
          reloadDocument={true}
        >
          <ItemCard hit={hit} favorites={favorites} />
        </Link>
      ));

    return (
      <Box className="algolia-search__autocomplete-no-results">
        <Typography variant="body1">No results</Typography>
      </Box>
    );
  };

  return (
    <div
      ref={inputRef}
      className={classNames("algolia-search__autocomplete", {
        "algolia-search__autocomplete--expand": open,
        "algolia-search__autocomplete--collapse": !open,
      })}
    >
      <TextField
        placeholder="Search the Marketplace"
        variant="outlined"
        fullWidth
        value={searchBoxQuery}
        onChange={(event) => refine(event.target.value)}
        onFocus={() => setOpen(true)}
        onKeyDown={handleKeyDown}
        inputRef={textFieldRef}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              className="algolia-search__autocomplete-icon"
              sx={{ mr: "-8px" }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { borderRadius: "8px" },
        }}
        className="algolia-search__autocomplete-input"
      />
      {open && (
        <Paper className="algolia-search__autocomplete-dropdown">
          {query.length > 0
            ? renderHits(currentHits)
            : renderHits(
                relatedProducts && relatedProducts.length > 0
                  ? relatedProducts
                  : currentHits,
              )}
          <Box
            className="algolia-search__autocomplete-footer"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Link
              onClick={() => {
                setOpen(false);
                textFieldRef.current?.blur();
              }}
              to={
                query.length > 0 && currentHits.length > 0
                  ? `/marketplace?query=${encodeURIComponent(query)}`
                  : "/categories/recommendations"
              }
              reloadDocument={
                isEmbedded || query.length === 0 || currentHits.length === 0
              }
              className="algolia-search__autocomplete-footer-link"
            >
              {query.length > 0 && currentHits.length > 0
                ? "Show All"
                : "All Recommended Newsletters"}
            </Link>
            <KeyboardArrowRightIcon sx={{ color: "primary.dark" }} />
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default CustomAutoComplete;
